@font-face {
  font-family: "Roboto";
  font-display: block;
  src: url(/static/media/Roboto-Regular.947d4eb3.eot) format('eot'),
       url(/static/media/Roboto-Regular.9f448c41.woff2) format('woff2'),
       url(/static/media/Roboto-Regular.878ad715.woff) format('woff'),
       url(/static/media/Roboto-Regular.3e1af3ef.ttf)  format('truetype'),
       url(/static/media/Roboto-Regular.4183b287.svg#Roboto-Regular) format('svg');
  font-weight: normal;
}

@font-face {
  font-family: "Roboto";
  font-display: block;
  src: url(/static/media/Roboto-Medium.0c23004a.eot) format('eot'),
       url(/static/media/Roboto-Medium.487071b8.woff2) format('woff2'),
       url(/static/media/Roboto-Medium.7b225d4d.woff) format('woff'),
       url(/static/media/Roboto-Medium.d0884059.ttf)  format('truetype'),
       url(/static/media/Roboto-Medium.b85ba5f5.svg#Roboto-Medium) format('svg');
  font-weight: 500;
}

@font-face {
  font-family: "Roboto";
  font-display: block;
  src: url(/static/media/Roboto-Bold.470488ec.eot) format('eot'),
       url(/static/media/Roboto-Bold.53cdd25b.woff2) format('woff2'),
       url(/static/media/Roboto-Bold.eb43b4c3.woff) format('woff'),
       url(/static/media/Roboto-Bold.ee7b96fa.ttf)  format('truetype'),
       url(/static/media/Roboto-Bold.29d3b882.svg#Roboto-Bold) format('svg');
  font-weight: 600;
}

@font-face {
  font-family: "Roboto";
  font-display: block;
  src: url(/static/media/Roboto-Bold.470488ec.eot) format('eot'),
       url(/static/media/Roboto-Bold.53cdd25b.woff2) format('woff2'),
       url(/static/media/Roboto-Bold.eb43b4c3.woff) format('woff'),
       url(/static/media/Roboto-Bold.ee7b96fa.ttf)  format('truetype'),
       url(/static/media/Roboto-Bold.29d3b882.svg#Roboto-Bold) format('svg');
  font-weight: 800;
}

@font-face {
  font-family: "Roboto";
  font-display: block;
  src: url(/static/media/Roboto-Light.97b9f3ce.eot) format('eot'),
       url(/static/media/Roboto-Light.742db475.woff2) format('woff2'),
       url(/static/media/Roboto-Light.c8405cfa.woff) format('woff'),
       url(/static/media/Roboto-Light.fc84e998.ttf)  format('truetype'),
       url(/static/media/Roboto-Light.9328008d.svg#Roboto-Light) format('svg');
  font-weight: 200;
}

@font-face {
  font-family: "Roboto";
  font-display: block;
  src: url(/static/media/Roboto-Thin.1869e98f.eot) format('eot'),
       url(/static/media/Roboto-Thin.141d68bb.woff2) format('woff2'),
       url(/static/media/Roboto-Thin.03fb3a93.woff) format('woff'),
       url(/static/media/Roboto-Thin.89e2666c.ttf)  format('truetype'),
       url(/static/media/Roboto-Thin.a60f3ded.svg#Roboto-Thin) format('svg');
  font-weight: 100;
}

@font-face {
  font-family: "Roboto";
  font-display: block;
  src: url(/static/media/Roboto-ThinItalic.4c781fe1.eot) format('eot'),
       url(/static/media/Roboto-ThinItalic.8c8e3f6d.woff2) format('woff2'),
       url(/static/media/Roboto-ThinItalic.4ced2a97.woff) format('woff'),
       url(/static/media/Roboto-ThinItalic.0fc25386.ttf)  format('truetype'),
       url(/static/media/Roboto-ThinItalic.79c653a6.svg#Roboto-ThinItalic) format('svg');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "Lora";
  font-display: block;
  src: url(/static/media/Lora-Regular.49135221.eot) format('eot'),
       url(/static/media/Lora-Regular.193c2312.woff2) format('woff2'),
       url(/static/media/Lora-Regular.f944e17e.woff) format('woff'),
       url(/static/media/Lora-Regular.c87345ce.ttf)  format('truetype'),
       url(/static/media/Lora-Regular.6145f4bf.svg#Lora-Regular) format('svg');
  font-weight: normal;
}

@font-face {
  font-family: "Lora";
  font-weight: normal;
  font-style: italic;
  src: url(/static/media/Lora-Italic.e3a45630.eot) format('eot'),
       url(/static/media/Lora-Italic.c70e70b6.woff2) format('woff2'),
       url(/static/media/Lora-Italic.dfedce01.woff) format('woff'),
       url(/static/media/Lora-Italic.d36745bb.ttf)  format('truetype'),
       url(/static/media/Lora-Italic.9d7c56ec.svg#Lora-Italic) format('svg');
  font-display: block;
}

@font-face {
  font-family: "Lora";
  font-display: block;
  src: url(/static/media/Lora-BoldItalic.636c1a16.eot) format('eot'),
       url(/static/media/Lora-BoldItalic.68693bc1.woff2) format('woff2'),
       url(/static/media/Lora-BoldItalic.13cf31d0.woff) format('woff'),
       url(/static/media/Lora-BoldItalic.04f84cd5.ttf)  format('truetype'),
       url(/static/media/Lora-BoldItalic.fe7da597.svg#Lora-BoldItalic) format('svg');
  font-weight: bold;
  font-style: italic;
}


html, body {
  font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  margin: 0;
  height: 100%;
}

#root {
  height: 100%;
}

.card {
  max-width: 500px;
  margin: 0 auto;
  border-radius: 4px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  padding: 20px 30px;
}

.card .main-card{
  margin-top: 50px;
  margin-bottom: 50px;
}

.main-header, .subtitle {
  margin: 0 0 20px 0;
  color: rgba(0, 0, 0, 0.54);
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.main-header-large-bottom{
  margin-bottom: 40px;
}

.main-header-text, .main-header-button-outer{

}
.main-header-button-outer{
  padding-left: 20px;
}

.header-note{
  margin: 0 0 20px 0;
  color: rgba(0, 0, 0, 0.7);
  font-size: 14px;
  line-height: 20px;
  font-weight: 300;
  font-style: italic;
  font-family: Lora;
  letter-spacing: .7px;
}


.subtitle{
  color: rgba(0, 0, 0, 0.44);
}

.input-wrapper{
  margin-bottom: 20px;
}

.label-text-outer{
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
.label-text{
  -webkit-flex: 1 1;
          flex: 1 1;
}
.label-prefix-icon{
  margin-right: 5px;
  margin-top: 5px;
}


.page {
  padding: 50px 20px;
}

.page.page-tight{
  padding: 30px 20px;
}

.indent{
  padding-left: 20px;
}

@media(max-width: 500px){
  .page {
    padding: 30px 15px;
  }
}


.steps{

}

.steps.steps-squeeze{
  max-width: 600px;
}

.steps li{
  margin: 0 0 20px 0;
  color: rgba(0, 0, 0, 0.7);
  font-size: 18px;
  line-height: 26px;
  font-weight: 300;
  font-family: Roboto;
  letter-spacing: .7px;
}

.step-btns-outer{
  position: relative;
  margin-top: 40px;
}

.step-btn-next-outer{
  float: right;
  margin-left: 20px;
}

.step-btn-prev-outer{
  float: right;
}

.wizard-step-outer{
  min-height: 300px;
}


.q-alert {
  margin: 20px 0;
  padding: 12px 10px;
  border-radius: 3px;
  border-width: 1px;
  border-style: solid;
  color:#333333;
  text-align: center;
  font-size: 12px;
}


.q-alert-red {
  padding: 12px 10px;
  border-radius: 3px;
  border-width: 1px;
  border-style: solid;
  color:#333333;
  text-align: center;
  font-size: 12px;
  background-color: #FFE7E7;
  border-color: #FFE7E7;
}


.device-pt-title {
  margin: 40px 0 20px 0;
  color: rgba(0, 0, 0, 0.6);
}


.devices-pt-list{
  display: -webkit-flex;
  display: flex;
}

.device-item-outer {
  max-width: 300px;
  -webkit-flex: 1 1;
          flex: 1 1;
  margin: 0 20px 20px 0;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
}

@media(max-width: 680px){
  .devices-pt-list{
    display: block;
  }
  .device-item-outer {
    max-width: 100%;
    width: 100%;
    margin: 0 0 20px 0;
  }
}


.device-row{
  padding: 10px 0;
  border-bottom: 1px solid #F0F0F0;
  font-size: 16px;
  color: grey;
  display: -webkit-flex;
  display: flex;
}

.device-row:first-child{
  border-top: 1px solid #F0F0F0;
}

.device-col{
  padding: 10px;
  -webkit-flex: 1 1;
          flex: 1 1;
}

.device-col-numb{
  max-width:100px;
}



.outer-pagination{
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}


.device-list-outer{
  margin-bottom: 30px;
}

.card-note-xl{
 color: #484848;
 font-size: 34px;
 line-height: 40px;
 margin-bottom: 20px;
 padding-left: 20px;
}

.card-note-md{
  color: #484848;
 font-size: 20px;
 line-height: 30px;
 margin-bottom: 20px;
 padding-left: 20px;
}
