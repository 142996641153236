
.step-btns-outer{
  position: relative;
  margin-top: 40px;
}

.step-btn-next-outer{
  float: right;
  margin-left: 20px;
}

.step-btn-prev-outer{
  float: right;
}

.wizard-step-outer{
  min-height: 300px;
}