

html, body {
  font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  margin: 0;
  height: 100%;
}

#root {
  height: 100%;
}