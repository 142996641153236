
.card-note-xl{
 color: #484848;
 font-size: 34px;
 line-height: 40px;
 margin-bottom: 20px;
 padding-left: 20px;
}

.card-note-md{
  color: #484848;
 font-size: 20px;
 line-height: 30px;
 margin-bottom: 20px;
 padding-left: 20px;
}