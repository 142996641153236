
.device-pt-title {
  margin: 40px 0 20px 0;
  color: rgba(0, 0, 0, 0.6);
}


.devices-pt-list{
  display: flex;
}

.device-item-outer {
  max-width: 300px;
  flex: 1;
  margin: 0 20px 20px 0;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
}

@media(max-width: 680px){
  .devices-pt-list{
    display: block;
  }
  .device-item-outer {
    max-width: 100%;
    width: 100%;
    margin: 0 0 20px 0;
  }
}