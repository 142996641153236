
.main-header, .subtitle {
  margin: 0 0 20px 0;
  color: rgba(0, 0, 0, 0.54);
  display: flex;
  align-items: center;
}

.main-header-large-bottom{
  margin-bottom: 40px;
}

.main-header-text, .main-header-button-outer{

}
.main-header-button-outer{
  padding-left: 20px;
}

.header-note{
  margin: 0 0 20px 0;
  color: rgba(0, 0, 0, 0.7);
  font-size: 14px;
  line-height: 20px;
  font-weight: 300;
  font-style: italic;
  font-family: Lora;
  letter-spacing: .7px;
}


.subtitle{
  color: rgba(0, 0, 0, 0.44);
}