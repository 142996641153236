@font-face {
  font-family: "Roboto";
  font-display: block;
  src: url('~fonts/Roboto/Roboto-Regular.eot?') format('eot'),
       url('~fonts/Roboto/Roboto-Regular.woff2') format('woff2'),
       url('~fonts/Roboto/Roboto-Regular.woff') format('woff'),
       url('~fonts/Roboto/Roboto-Regular.ttf')  format('truetype'),
       url('~fonts/Roboto/Roboto-Regular.svg#Roboto-Regular') format('svg');
  font-weight: normal;
}

@font-face {
  font-family: "Roboto";
  font-display: block;
  src: url('~fonts/Roboto/Roboto-Medium.eot?') format('eot'),
       url('~fonts/Roboto/Roboto-Medium.woff2') format('woff2'),
       url('~fonts/Roboto/Roboto-Medium.woff') format('woff'),
       url('~fonts/Roboto/Roboto-Medium.ttf')  format('truetype'),
       url('~fonts/Roboto/Roboto-Medium.svg#Roboto-Medium') format('svg');
  font-weight: 500;
}

@font-face {
  font-family: "Roboto";
  font-display: block;
  src: url('~fonts/Roboto/Roboto-Bold.eot?') format('eot'),
       url('~fonts/Roboto/Roboto-Bold.woff2') format('woff2'),
       url('~fonts/Roboto/Roboto-Bold.woff') format('woff'),
       url('~fonts/Roboto/Roboto-Bold.ttf')  format('truetype'),
       url('~fonts/Roboto/Roboto-Bold.svg#Roboto-Bold') format('svg');
  font-weight: 600;
}

@font-face {
  font-family: "Roboto";
  font-display: block;
  src: url('~fonts/Roboto/Roboto-Bold.eot?') format('eot'),
       url('~fonts/Roboto/Roboto-Bold.woff2') format('woff2'),
       url('~fonts/Roboto/Roboto-Bold.woff') format('woff'),
       url('~fonts/Roboto/Roboto-Bold.ttf')  format('truetype'),
       url('~fonts/Roboto/Roboto-Bold.svg#Roboto-Bold') format('svg');
  font-weight: 800;
}

@font-face {
  font-family: "Roboto";
  font-display: block;
  src: url('~fonts/Roboto/Roboto-Light.eot?') format('eot'),
       url('~fonts/Roboto/Roboto-Light.woff2') format('woff2'),
       url('~fonts/Roboto/Roboto-Light.woff') format('woff'),
       url('~fonts/Roboto/Roboto-Light.ttf')  format('truetype'),
       url('~fonts/Roboto/Roboto-Light.svg#Roboto-Light') format('svg');
  font-weight: 200;
}

@font-face {
  font-family: "Roboto";
  font-display: block;
  src: url('~fonts/Roboto/Roboto-Thin.eot?') format('eot'),
       url('~fonts/Roboto/Roboto-Thin.woff2') format('woff2'),
       url('~fonts/Roboto/Roboto-Thin.woff') format('woff'),
       url('~fonts/Roboto/Roboto-Thin.ttf')  format('truetype'),
       url('~fonts/Roboto/Roboto-Thin.svg#Roboto-Thin') format('svg');
  font-weight: 100;
}

@font-face {
  font-family: "Roboto";
  font-display: block;
  src: url('~fonts/Roboto/Roboto-ThinItalic.eot?') format('eot'),
       url('~fonts/Roboto/Roboto-ThinItalic.woff2') format('woff2'),
       url('~fonts/Roboto/Roboto-ThinItalic.woff') format('woff'),
       url('~fonts/Roboto/Roboto-ThinItalic.ttf')  format('truetype'),
       url('~fonts/Roboto/Roboto-ThinItalic.svg#Roboto-ThinItalic') format('svg');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "Lora";
  font-display: block;
  src: url('~fonts/Lora/Lora-Regular.eot?') format('eot'),
       url('~fonts/Lora/Lora-Regular.woff2') format('woff2'),
       url('~fonts/Lora/Lora-Regular.woff') format('woff'),
       url('~fonts/Lora/Lora-Regular.ttf')  format('truetype'),
       url('~fonts/Lora/Lora-Regular.svg#Lora-Regular') format('svg');
  font-weight: normal;
}

@font-face {
  font-family: "Lora";
  font-weight: normal;
  font-style: italic;
  src: url('~fonts/Lora/Lora-Italic.eot?') format('eot'),
       url('~fonts/Lora/Lora-Italic.woff2') format('woff2'),
       url('~fonts/Lora/Lora-Italic.woff') format('woff'),
       url('~fonts/Lora/Lora-Italic.ttf')  format('truetype'),
       url('~fonts/Lora/Lora-Italic.svg#Lora-Italic') format('svg');
  font-display: block;
}

@font-face {
  font-family: "Lora";
  font-display: block;
  src: url('~fonts/Lora/Lora-BoldItalic.eot?') format('eot'),
       url('~fonts/Lora/Lora-BoldItalic.woff2') format('woff2'),
       url('~fonts/Lora/Lora-BoldItalic.woff') format('woff'),
       url('~fonts/Lora/Lora-BoldItalic.ttf')  format('truetype'),
       url('~fonts/Lora/Lora-BoldItalic.svg#Lora-BoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
}