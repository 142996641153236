

.steps{

}

.steps.steps-squeeze{
  max-width: 600px;
}

.steps li{
  margin: 0 0 20px 0;
  color: rgba(0, 0, 0, 0.7);
  font-size: 18px;
  line-height: 26px;
  font-weight: 300;
  font-family: Roboto;
  letter-spacing: .7px;
}