

.q-alert {
  margin: 20px 0;
  padding: 12px 10px;
  border-radius: 3px;
  border-width: 1px;
  border-style: solid;
  color:#333333;
  text-align: center;
  font-size: 12px;
}


.q-alert-red {
  padding: 12px 10px;
  border-radius: 3px;
  border-width: 1px;
  border-style: solid;
  color:#333333;
  text-align: center;
  font-size: 12px;
  background-color: #FFE7E7;
  border-color: #FFE7E7;
}