
.input-wrapper{
  margin-bottom: 20px;
}

.label-text-outer{
  display: flex;
  align-items: center;
}
.label-text{
  flex: 1;
}
.label-prefix-icon{
  margin-right: 5px;
  margin-top: 5px;
}