

.device-row{
  padding: 10px 0;
  border-bottom: 1px solid #F0F0F0;
  font-size: 16px;
  color: grey;
  display: flex;
}

.device-row:first-child{
  border-top: 1px solid #F0F0F0;
}

.device-col{
  padding: 10px;
  flex: 1;
}

.device-col-numb{
  max-width:100px;
}

